<template>
    <div class="mb-content" v-if="isMobile()" @click="aboutShow=false,businessShow=false">
        <header><img src="../../assets/images//LOGO_mb.png" alt=""></header>
        <div class="tabs-content" id="Tab" ref="tab" v-show="watchRoute !== '/home'">
            <div :class="['tabs', tabShow?'tab-fixed':'']">
                <div :class="['item1',watchRoute=='/home'?'active':'']" @click="goto('Home')">首页</div>
                <div :class="['item2',watchRoute.includes('about')?'active':'']">
                    <span @click.stop="aboutShow = !aboutShow,businessShow=false">关于中科信恒</span><i></i>
                    <div class="down-list" v-if="aboutShow">
                        <div @click="goto('Introduce')">集团简介</div>
                        <div @click="goto('Course')">集团发展</div>
                    </div>
                </div>
                <div :class="['item3',watchRoute.includes('business')?'active':'']">
                    <span @click.stop="businessShow = !businessShow,aboutShow=false">核心业务</span><i></i>
                    <div class="down-list down-list1" v-if="businessShow">
                        <div @click.stop="goto('Environment')">环保科技产业</div>
                        <div @click.stop="goto('Education')">教育科技产业</div>
                    </div>
                </div>
                <div :class="['item3',watchRoute.includes('news')?'active':'']" @click="goto('Dynamic')">新闻动态</div>
            </div>
        </div>
        <router-view class="router-view" />
        <div class="footer" v-if="watchRoute !== '/news/detail'">
            <div>
                <a href="tel:07713924090"> 
                    <i class="tel"></i>
                    <p>电话咨询</p>
                </a>
            </div>
            <div @click="goto('Map')">
                <i class="position"></i>
                <p>在线地图</p>
            </div>
            <div @click="goto('Contact')">
                <i class="say"></i>
                <p>在线留言</p>
            </div>
        </div>
    </div>
    <div class="home-content" v-else>
        <header>
            <div class="header-content">
                <div class="header-content-main">
                    <img src="../../assets/images/logo.png" alt="">
                    <div class="nav">
                        <div class="nav-content">
                            <div 
                                v-cloak
                                :class="['nav-item',item.isClk?'nav-item-active':'',item.isHover?'nav-item-hover':'']" 
                                v-for="item in navList" 
                                :key="item.name"
                                @click="ClkNav(item)"
                                @mouseenter="Enter(item)"
                                @mouseleave="Leave(item)">
                                {{item.name}}
                                <div class="down" v-if="item.children.length>0">
                                    <p><i></i></p>
                                    <div v-for="(child,index) in item.children" :key="index" @click.stop="ClkNav(child)">{{child.cname}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <router-view class="router-view" />
        <footer>
            <div class="footer">
                <div class="footer-item1">
                    <p class="text1">版权所有 © 2017 广西中科信恒投资集团有限公司    </p>
                    <p class="text2">地址：广西南宁市青秀区中柬路8号龙光世纪A座707室</p>
                </div>
                <div  class="footer-item2">
                    <!-- <img src="../../assets/logo.png" alt="">
                    <span class="vx">关注微信公众 号</span> --> 
                    <div class="ban">网站备案号：桂ICP备2021008231号-1</div>
                    <div class="ban ban-gongan"><i class="gongan"></i> 桂公网安备 45010302002668号</div>
                </div>
            </div>
        </footer>
        <div class="top">
            <transition name="top-slide-fade">
                <div v-if="topShow" @click="toTop"></div>
            </transition>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
export default {
    name: "page",
    setup() {
        const navList = ref([
            {
                name: '首页',
                isClk: false,
                isHover: false,
                routePath: '/home',
                children: []
            },{
                name: '关于中科信恒',
                isClk: false,
                isHover: false,
                routePath: '/about',
                children: [
                    {cname: '集团介绍', routePath: '/about/introduce'},
                    {cname: '发展历程', routePath: '/about/course'},
                    {cname: '集团荣誉', routePath: '/about/honor'}
                ]
            },{
                name: '核心业务',
                isClk: false,
                isHover: false,
                routePath: '/business',
                children: [
                    {cname: '环保科技产业', routePath: '/business/environment'},
                    // {cname: '科技产业', routePath: '/business/technology'},
                    {cname: '教育科技产业', routePath: '/business/education'}
                ]
            },{
                name: '新闻中心',
                isClk: false,
                isHover: false,
                routePath: '/news',
                children: [
                    // {cname: '行业动态', routePath: '/introduce'},
                    {cname: '集团动态', routePath: '/news/dynamic'},
                    {cname: '视频中心', routePath: '/news/video'}
                ]
            },{
                name: '联系我们',
                isClk: false,
                isHover: false,
                routePath: '/contact',
                children: []
            }
        ])
        const topShow = ref(false)
        const router = useRouter()
        const routerPath = ref(null)
        routerPath.value = router.currentRoute.value.path
        const watchRoute = ref('')
        watchRoute.value = routerPath.value
        const tabShow = ref(false)
        const tab = ref(null)
        const hg = ref(0)
        const aboutShow = ref(false)
        const businessShow = ref(false)
        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const defaultNav = () => {
            for (const el of navList.value) {
                if(routerPath.value.includes(el.routePath)) {
                    el.isClk = true
                }else {
                    el.isClk = false
                }
            }
        }
        defaultNav()

        const ClkNav = (item) => {
            for (const el of navList.value) {
                if(!el.routePath.includes(item.routerPath)){
                    el.isClk = false
                    router.push({
                        path: item.routePath
                    })
                }
            }
            item.isClk = true
        }

        const Enter = (item) => {
            item.isHover = true
        }

        const Leave = (item) => {
            item.isHover = false
        }

        const toTop = () => {
            var timer = setInterval(function(){
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5); 
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if(osTop === 0){
                    clearInterval(timer);
                }
            },20)
        }

        const scrollFunTab = () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop - hg.value > 0) {
                tabShow.value = true
            }else {
                tabShow.value = false
            }
        }

        const goto = (name) => {
            router.push({
                name: name
            })
        }

        onMounted(() => {
            if(isMobile()) {
                setTimeout(() => {
                    hg.value = tab.value.getBoundingClientRect().top
                    window.addEventListener("scroll", scrollFunTab, true); //监听滚动事件
                }, 500);
            }else {
                window.addEventListener("scroll", () => {
                    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                    if(scrollTop > 5) {
                        topShow.value = true
                    }else {
                        topShow.value = false
                    }
                }, true); //监听滚动事件
            }
            
        })

        onBeforeRouteUpdate((to) => {
            watchRoute.value = to.path
            for (const el of navList.value) {
                if(to.path.includes(el.routePath)) {
                    el.isClk = true
                }else {
                    el.isClk = false
                }
            }
        })

        return {
            navList,
            topShow,
            ClkNav,
            Enter,
            Leave,
            toTop,
            isMobile,
            tab,
            tabShow,
            aboutShow,
            businessShow,
            routerPath,
            watchRoute,
            goto
        }
    },
}
</script>

<style lang="scss">
    .home-content {
        min-width: 1200px;
        header {
            height: 100px;
            .header-content {
                width: 100%;
                text-align: center;
                background: #fff;
                position: fixed;
                top: 0;
                z-index: 111;
                .header-content-main {
                    display: inline-block;
                    width: 1330px;
                    height: 100px;
                }
                .nav {
                    display: inline-block;
                    vertical-align: top;
                    .nav-content {
                        display: flex;
                        margin-top: 22px;
                        .nav-item {
                            display: inline-block;
                            margin: 0 10px;
                            font-size: 18px;
                            padding: 15px;
                            border-bottom: 3px solid transparent;
                            cursor: pointer;
                            position: relative;

                            .down {
                                background: #009fe9;
                                font-size: 16px;
                                color: #fff;
                                text-align: center;
                                width: 120%;
                                position: absolute;
                                top: 68px;
                                left: -10px;
                                z-index: 11;
                                display: none;

                                >div {
                                    padding: 10px;
                                }
                                >div:hover {
                                    background: #1576a3;
                                }
                            }
                            .down p {
                                width: 100%;
                                position: absolute;
                                top: -16px;
                                left: 0;
                                i {
                                    display: inline-block;
                                    border: 5px solid transparent;
                                    border-bottom-color: #009fe9;
                                }
                            }
                        }
                        .nav-item:hover,.nav-item-active {
                            display: inline-block;
                            border-bottom: 3px solid #009fe9;
                        }
                        .nav-item-hover {
                            .down {
                                display: block;
                            }
                        }
                    }
                }
                img {
                    height: 66px;
                    margin-left: 100px;
                    margin-top: 17px;
                    margin-right: 100px;
                    vertical-align: bottom;
                }
            }
        }
        footer {
            background: #005bac;
            padding-top: 15px;
            padding-bottom: 30px;
            .footer {
                width: 1330px;
                margin: 0 auto;
                color: #fff;
                overflow: hidden;
                >div {
                    .text1 {
                        margin-top: 20px;
                    }
                    .text2 {
                        margin-top: 5px;
                    }
                    img {
                        width: 90px;
                        height: 90px;
                        vertical-align: top;
                        background: #fff;
                    } 
                    .vx {
                        display: inline-block;
                        margin-top: 30px;
                        margin-left: 10px;
                    }                 
                }
                .footer-item1 {
                    display: inline-block;
                }
                .footer-item2 {
                    float: right;
                    margin-right: 20px;
                    .ban {
                        margin-top: 15px;
                    }
                    .ban-gongan {
                        margin-top: 5px;
                    }
                    .gongan {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url(../../assets/images/gongan.png) no-repeat;
                        background-size: cover;
                        vertical-align: bottom;
                    }
                }
            }
        }
        .top {
            position: fixed;
            bottom: 50px;
            right: 20px;
            width: 55px;
            height: 55px;
            overflow: hidden;
            >div {
                width: 100%;
                height: 100%;
                background: url(../../assets/images/top.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        .top-slide-fade-enter-active {
            transition:  all .5s ease-in;
        }
        .top-slide-fade-leave-active {
            transition:  all .5s ease-in;
        }

        .top-slide-fade-enter-from,
        .top-slide-fade-leave-to {
            transform: translateY(55px);
        }
    }
    .mb-content {
        header {
            height: 52px;
            line-height: 52px;
            color: #fff;
            font-size: 20px;
            text-align: center;

            img {
                width: auto;
                height: 100%;
                vertical-align: top;
            }
        }
        .tabs-content {
            height: 50px;
            .tabs {
                display: flex;
                font-size: 0.28rem;
                >div {
                    text-align: center;
                    line-height: 50px;
                    position: relative;
                    .down-list {
                        position: absolute;
                        bottom: calc(-35px - 0.9rem);
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 2rem;
                        z-index: 100;
                        line-height: normal;
                        padding-bottom: 0.3rem;
                        border-radius: 0.1rem;
                        box-shadow: 0 0 0.75rem 0 rgb(0 0 0 / 10%);
                        background: #fff;
                        >div {
                            margin-top: 0.3rem;
                        }
                    }
                    .down-list1 {
                        width: 2.5rem;
                    }
                }
                .active {
                    border-bottom: 2px solid #25477f;
                }
                .item1 {width: 15%;}
                .item2 {width: 33%;}
                .item3 {width: 26%;}
                i {
                    display: inline-block;
                    width: 0.34rem;
                    height: 0.36rem;
                    vertical-align: middle;
                    margin-left: 2px;
                    background: url(../../assets/images/arrow.png) no-repeat;
                    background-size: cover;
                }
            }
            .tab-fixed {
                width: 100%;
                background: #fff;
                box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 20%);
                position: fixed;
                top: 0;
                 z-index: 100;
            }
        }
        .footer {
            display: flex;
            width: 100%;
            height: 1.2rem;
            background: #25477f;
            color: #fff;
            font-size: 0.28rem;
            position: fixed;
            bottom: 0;
            >div {
                flex: 1;
                text-align: center;
                margin-top: 0.1rem;
                i {
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.5rem;
                }
                .tel {
                    background: url(../../assets/images/tel.png) no-repeat;
                    background-size: cover;
                }
                .position {
                    background: url(../../assets/images/position.png) no-repeat;
                    background-size: cover;
                }
                .say {
                    background: url(../../assets/images/say.png) no-repeat;
                    background-size: cover;
                }
            }
            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
</style>