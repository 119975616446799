import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Page',
    component: Home,
    meta: {
      title: '首页',
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/home/components/Home.vue')
      },{
        path: '/about',
        name: 'About',
        meta: {
          title: '集团介绍',
        },
        redirect: '/about/introduce',
        component: () => import('@/views/about/Index.vue'),
        children: [
          {
            path: '/about/introduce',
            name: 'Introduce',
            meta: {
              title: '集团介绍',
            },
            component: () => import('@/views/about/components/Introduce.vue')
          },{
            path: '/about/honor',
            name: 'Honor',
            meta: {
              title: '集团荣誉',
            },
            component: () => import('@/views/about/components/Honor.vue')
          },{
            path: '/about/course',
            name: 'Course',
            meta: {
              title: '发展历程',
            },
            component: () => import('@/views/about/components/Course.vue')
          }
        ]
      },{
        path: '/business',
        name: 'Business',
        meta: {
          title: '核心业务',
        },
        redirect: '/business/environment',
        component: () => import('@/views/business/Index.vue'),
        children: [
          {
            path: '/business/environment',
            name: 'Environment',
            meta: {
              title: '环保科技产业',
            },
            component: () => import('@/views/business/components/Environment.vue')
          },{
            path: '/business/technology',
            name: 'Technology',
            meta: {
              title: '科技产业',
            },
            component: () => import('@/views/business/components/Technology.vue')
          },{
            path: '/business/education',
            name: 'Education',
            meta: {
              title: '教育科技产业',
            },
            component: () => import('@/views/business/components/Education.vue')
          }
        ]
      },{
        path: '/news',
        name: 'News',
        meta: {
          title: '新闻中心',
        },
        redirect: '/news/dynamic',
        component: () => import('@/views/news/Index.vue'),
        children: [
          {
            path: '/news/dynamic',
            name: 'Dynamic',
            meta: {
              title: '集团动态',
            },
            component: () => import('@/views/news/components/Dynamic.vue')
          },{
            path: '/news/video',
            name: 'Video',
            meta: {
              title: '视频中心',
            },
            component: () => import('@/views/news/components/Video.vue')
          },{
            path: '/news/detail',
            name: 'Detail',
            meta: {
              title: '详情',
            },
            component: () => import('@/views/news/components/Detail.vue')
          }
        ]
      },{
        path: '/contact',
        name: 'Contact',
        meta: {
          title: '联系我们',
        },
        component: () => import('@/views/contact/Index.vue')
      }
    ],
  },{
    path: '/map',
    name: 'Map',
    meta: {
      title: '地址详情',
    },
    component: () => import('@/views/map/Index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
