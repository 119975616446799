<template>
  <router-view class="router-view" />
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  font-size: 16px;
  font-family: "微软雅黑";
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
}
html {
  width: 100%;
  height: 100%;
}
[v-cloak] {
  display: none;
}
</style>
